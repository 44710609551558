import { en } from 'vuetify/locale';

export default {
    select: {
        title: "language",
    },
    // 底部tabbar
    tabbar: {
        item1: "Home",
        item2: "Recharge",
        item3: "Invite",
        item4: "Bonus",
        item5: "Personal",
        tipsContent: "Current user logged in on another device ?",
        Tips:"Tips"
    },
    // 应用栏目
    appbar: {
        deposit: "Deposit",
        register:"Register",
        login:"Login",
        download:"Download",
        Earn:"Earn",
        Bonus:"Bonus",
    },
    // 页尾
    pagefooting: {
        AboutUS: "About Us",
        content: "This website offers risky experience gaming. To be a user of our website, you must be over 18 years old. we are not responsible",
        copyright: "©2023 XFUN.APP ALL RIGHTS RESERVED.",
        RightsReserved:"All rights reserved",
    },
    // 搜索栏
    search: {
        Search: "Search",
        Recommended: "Recommended Games",
        placeholder:"Search Games",
        noMore:"No More Items",
        loading:"Loading"
    },
    // 游戏列表
    gamelist: {
        "Load More": "Load More",
        "More": "More",
        "GameType": "GameType"
    },
    logindialog: {
        "Email": "Email",
        "Enter email address": "Enter email address",
        "Mobile": "Mobile",
        "PassWord":"PassWord",
        "Forgotloginpassword?":"Forgot login password?",
        "Login":"Login",
        "SignUpnnow":"Sign up now",
        "Rememberme": "Remember me"
    },
    registerdialog: {
        "Email": "Email",
        "Verificationcode": "Verification code",
        "Code": "Code"
    },
    //个人信息
    userinfo: {
        Profile: "My Profile",
        EditProfile:" Edit Profile",
        Balance: "Balance",
        BalanceTrend:"Balance Trend",
        Deposit:"Deposit",
        Withdraw:"Withdraw",
        Transaction:"Transaction",
        BetsHistory:"History",
        RolloverProgress:"Rollover Progress",
        StreamingCentre:"Streaming Centre",
        AccountPrivacy:"Account & Privacy",
        Password:"Password",
        Messages:"Messages",
        promotion:"Promotion",
        Logout:"Logout",
        ExitAccount : "Do you want to exit the current user?",
        CompleteAccount:"Complete account and privacy before withdrawal",
        Other:"Other",
        invite:"Invite",
        readRain:"ReadRain",
        Welcome:"welcome to WWW.XFUN.APP",
        LoginOrRegister:"Please register or login first.",
        LiveChat:"LiveChat",
    },
    //修改头像
    changeavatar: {
        ChangeAvatar: "Change Avatar", 
        Avatatr: "Avatar",
        Animal: "Animal",
        confirm:"Confirm",
    },
    //修改昵称
    editProfile:{
        EditProfile:"Edit Profile",
        NickName:"Nickname",
        DisplayInformation:"Display Information",
        PersonalInformation:"Personal Information",
        MobilePhone:"Mobile Phone",
        Email:"Email",
        name:"Name",
        phone:"Phone",
        confirm:"Confirm",
    },
    //充值
    deposit:{
        Deposit:"Deposit",
        Recommended:"Recommended",
        OtherOption:"Other Option",
        OptionofDeposit:"Option of Deposit",
        Earn:"Earn",
        Bonus:"Bonus",
    },
    //绑定手机
    bindCPF:{
        BindCPF:"Bind CPF",
        CPFAccount:"CPF Account",
        FirstName:"First Name",
        LastName:"Last Name",
        EnterPIXsCPFAccount:"Enter PIXs CPF Account",
        EnterFirstName:"Enter First Name",
        EnterLastName:"Enter Last Name",
        Remark:"Remark: When successfully linked CPF, only the linked CPF allows deposit & withdrawal.",
        success:"success",
        bindcpfsuccess:"bind cpf success",
        MoneyisRequired:"Money is Required",
        Pixformaterror:"Pix format error",
        confirm:"Confirm",
    },
    //提现
    withdrawal:{
        Withdrawal:"Withdrawal",
        OptionofWithdrawal:"Option of Withdrawal",
        Recommended:"Recommended",
        OtherOption:"Other Option",
        According:"According to our fair gaming policy, only verified and activated members who have made a deposit are able to access our withdrawal feature. We kindly ask you to make a deposit of any amount to unlock the withdrawal function. Thank you for your cooperation and understanding.",
    },
    //记录吧
    transaction:{
        Transaction:"Transaction",
        Deposit:"Deposit",
        Withdraw:"Withdrawal",
        loadMore:"loadMore",
        Nomore:"No more",
        NoRecords:"No Records",
    },
    //下注记录
    betsHistory:{
        BetsHistory:"Bets History",
        TotalBet:"Total Bet",
        TotalWin:"Total Win",
        BettingTime:"Betting Time",
        Bet:"Bet",
        WinLose:"Win/Lose",
        NoRecords:"No Records",
        selectDate:"select date",
        confirm:"Confirm",
        cancel:"Cancel",
    },
    vip:{
        title:"VIP",
        Level:"Level",
        Need:"Need",
        content1:"Current VIP level permissions",
        Achieve:"Achieve",
        content2:"1. Get",
        content2_1:"Level up",
        content3:"2. Maximum number of daily withdrawals:",
        content4:"3. Daily maximum withdrawal:",
        content5:"4. Number of daily spins: Total",
        content6:"5. Weekly bonus that can be purchased: Silver, Gold, Diamond",
        content6_1:"5. Weekly bonus that can be purchased: Silver, Gold, Diamond, SVIP",
        content7:"6. Unlock new character avatars",
        rules1:"How to get VIP experience",
        rules2:"1. You can gain VIP experience through effective betting turnover.",
        rules3:"2. Effective bet: R$1=1 experience",
    },
    depositPIX:{
        DepositPIX:"Deposit PIX",
        give:"+R$",
        ExtraGive:"Extra+R$",
        AvailableBalance:"Available Balance",
        money:"money",
        Pleaseenteramount:"Please enter amount",
        FirstDeposit:"First Deposit",
        Deposit:"Deposit",
        content:"If deposit is not received within 10 minutes, please contact Live Support for further assistance.",
        Back:"Back",
        MoneyRules:"Input Money must be an integer",
        MoneyRules_1:"Money is not in the range.",
        MoneyRules_2:"Money is Required.",
        limitTimeText:"Participate in limited-time recharge and rebate activities",
        firstDepositText:"Make your first deposit and get a bonus of up to"
    },
    DepositUSDT:{
        DepositUSDT:"Deposit USDT",
        give:"+R$",
        ExtraGive:"Extra+R$",
        AvailableBalance:"Available Balance",
        money:"money",
        Pleaseenteramount:"Please enter amount",
        Deposit:"Deposit",
        content:"If deposit is not received within 10 minutes, please contact Live Support for further assistance.",
        Back:"Back",
        MoneyRules:"Input Money must be an integer",
        MoneyRules_1:"Money is not in the range.",
        MoneyRules_2:"Money is Required.",
    },
    bindMobile:{
        BindMobile:"Bind Mobile",
        Mobile:"Mobile",
        EnterMobileNo:"Enter Mobile No.",
        VerificationCode:"Verification Code",
        Code:"Code",
        SendCode:"Send Code",
        EnterVerificationcode:"Enter Verification code",
        confirm:"Confirm",
    },
    bindEmail:{
        BindEmail:"Bind Email",
        EmailAddress:"Email",
        EnterEmailAddress:"Enter Email Address",
        VerificationCode:"Código de verificação",
        Code:"Código",
        EnterVerificationcode:"Digite o código de verificação",
        confirm:"Confirmar",
         SendCode:"Send Code",
         Email:"Email",
    },
    modifyPassword:{
        ModifyPassword:"Modify Password",
        CurrentPassword:"Current Password",
        EnterCurrentPassword:"Enter Current Password",
        NewPassword:"New Password",
        EnterNewPassword:"Enter New Password",
        ConfirmPassword:"Confirm Password",
        ReEnterNewpassword:"Re-Enter New password",
        confirm:"Confirm ",
    },
    Message:{
        Message:"Message",  
        Messages:"Messages",
        MessageDetail:"Message Detail",
        SystemMessages:"System",
        personalMessage:"Personal",
        NoRecords:"No Records",
    },
    AccountAndPrivacy:{
        AccountAndPrivacy:"Account & Privacy",
        LinkMobileNo:"Link Mobile No.",
        LinkEmailAddress:"Link Email Address",  
    },
    promotion:{
        Share:"Refer and Earn",
        RegisterYesterday:"Register yesterday",
        TotalRegistration:"Total Registration",
        TotalBonus:"Total bonus",
        FirstDeposit:"First Deposit",
        BettingRewards:"Betting Rewards",
        MyRewards:"My rewards",
        Total:"Total",
        TotalInvitedFriends:"Total invited friends",
        ShareRecommendationLink:"Share this link or invitation code with your friends",
        ShareCommunity:"Share with your social community",
        MyRecommendedCode:"My recommended code",
        RewardRules:"Reward rules",
        FirstDeposits:"First Deposits",
        Reward:"Reward",
        All:"All",
        Level:"Level",
        YesterDay:"Yesterday",
        RuleSpecification:"Rule specification",
        content_1:"Earn cash back when your friends bet",
        content_2:"The invited friend registers and completes the first Deposit",
        content_3:"Invite and specific number of sub-users to receive rewards.",
        content_5:"Total  Rewards",
        content_6:" of your friend’s effective bet in ",
        content_6_1:" games will be used as your commission",
        content_7: "The friends you directly invite are level 1, the friends invited by level 1 are level 2, and the friends invited by level 2 are level 3.",
        contenet_7_1:"The commission rate you can earn is",
        content_8:"Bonus will be distributed automatically, and you can bet or withdraw.",
        content_9_1:"Valid users are new users who first deposit more than ",
        content_9_2:"and register by using the invitation link.",
        content_10:"It is prohibited to use the same IP and the same device to create accounts for arbitrage behavior.",
        content_11:"Any illegal behavior is strictly prohibited, We reserve the right to cancel any bonus obtained through fraud (whether a single player or a group of players), including deducting the bonus from the account and the right to participate and take necessary action to recover the value lost due to arbitrage behavior.",
        chatGroup:"Official XFUN Telegram Agent CHAT Group",
        chatGroupText:"Click the icon button on the left to join our TG official alliance group. We will provide you with professional alliance language and professional alliance tools. Help you get real benefits.",
        chatGroupText1:"If you have a large audience and fans. We offer you special conditions to customize your referral program! For more details, please contact us: ",
    },
    promotionAbout:{
        question1:'What is XFUN inviting friends?',
        answer1:'XFUN inviting friends is a project where we provide you with the opportunity to make money every day. You only need to provide us with the opportunity to receive more players.',
        question2:'How much does it cost to attend the event?',
        answer2:'Totally free!',
        question3:'Can I get a grant even if I do not have a website?',
        answer3:'sure. You can use social media or email for marketing.',
        question4:'What is my income?',
        answer4:'As a member of our affiliate network, you will be compensated on a revenue share model. You can get more than 70% commission reward.',
        answer5:'Every time a player you nominate places a bet, you will receive a varying percentage of the commission, regardless of whether they win or lose.',
    },
    DailyTasks:{
        DailyTasks:"Daily Tasks",
        points:"Complete the tasks to earn points",
        Receive:"Receive",
        Done:"Done",
        Open:"Open",
        NoRecords:"No Records",
        new1:"New",
        Daily:"Daily",
        Weekly:"Weekly",
        bonus:"bonuses",
        GiftAmount:"credits",
        pointsText:"points",
        content_help_1:"1. New user tasks: Only one time",
        content_help_2:'2. Daily tasks: Refresh at 0:00 every day',
        content_help_3:"3. Weekly tasks: Refresh every Monday at 0:00",
        content_help_4:'4. Get bonuses, credits , points after  Complete tasks.If your points reach the target of treasure chest, you can open and receive bonuses.',
        content_help_5:"5. Points and treasure chest refresh at 0:00 every Monday",
        title:"Novice Tasks",
        Ends:"Ends"
    },
    DailyTasksHistory:{
        title:"Daily Tasks History",
        bonus:"bonuses",
        GiftAmount:"credits",
        Total:"Total",
        Time:"Time",
        NoRecords:"No Records",
        Reward:"Reward",
        selectDate:"select date",
        points:"points",
    },
    gameSection:{
        More:"More",
    },
    RouletteDetail:{
        RouletteDetail:"Roulette Detail",
        MYscoresDetails:"MY scores Details",
        CurrentPoints:"Current Points",
        Points:"Points",
        Period:"Period",
        SignIn:"Sign In",
        Deposit:"Deposit",
        Betting:"Betting",
        BettingGuests:"Betting guests",
        DepositedGuests:"Deposited guests",
        MyHistory:"My history",
        Meeting:"Meeting",
        Model:"Model",
        consume:"Consume",
        obtain:"Obtain",
        confirm:"Confirm",
        cancel:"cancel",
        Scores:"Scores",
        Rewards:"Rewards",
        Nomore:"No more",
    },
    RouletteRules:{
        Rules:"FAQ",
        Title:"Roulette Rules"
    },
    RouletteLog:{
        RouletteRules:"Bonus Record",
        Record:"Record",
        level:"Level",
        Prize:"Prize",
        Vip:"Vip"
    },
    RouletteShare:{
        RouletteShare:"Roulette Share",
        Congratulations:"Congratulations",
        ViewPoints:"View Bonuses",
    },
    Roulette:{
        Score:"Score",
        InsufficientPoints:"There's not enough chance",
    },
    login:{
        EnterEmailAddress:"Enter email address",
        Mobile:"Mobile",
        EnterMobileNo:"Enter Mobile No.",
        Password:"Password",
        NewPassword:"New Password",
        ForgotLoginPassword:"Forgot login password",
        RememberMe:"Remember me",
        SignUpNow:"Sign up now",
        Login:"Login",
        EnterAccountNumber:"Enter Mobile No/Email"
    },
    fab: {
        Title:"Bonus",
        Function:"Function",
        Activity:"Activity"
    },
    firstCharge:{
        DepositRewards:"Deposit Rewards",
        Deposit:"Deposit",
        PromotionStandard:"Promotion Standard",
        content1:"When newly registered member deposits",
        content2:"for the first time, he will receive a bonus of up to",
        content3:"The bonus will be automatically transferred to your wallet."
    },
    forgot:{
        EnterEmailAddress:"Enter email address",
        Mobile:"Mobile",
        EnterMobileNo:"Enter Mobile No.",
        MobileNo:"Mobile No.",
        Code:"Code",
        EnterVerificationCode:"Enter Verification Code",
        SendCode:"Send Code",
        EnterYourPassword:"Enter your password",
        ReEnterPassword:"Re-enter password",
        LoginInNow:"Login in now",
        Password:"Password",
        Continue:"Continue",
        Email: "Email",
        VerificationCode:"Verification Code",
    },
    register:{  
        VerificationCode:"Verification Code",
        SendCode:"Send Code",
        Password:"Password",
        content_1:"I agree with user agreement",
        RememberMe:"Remember me",
        Continue:"Continue",
        LoginInNow:"Login in now",
        Email: "Email",
        EnterEmailAddress: "Enter email address",
        Mobile: "Mobile",
        MobileNo:"Mobile No.",
        EnterMobileNo:"Enter Mobile No.",
        Code:"Code",
        EnterVerificationCode:"Enter Verification Code",
        EnterYourPassword:"Enter your password",
        EnterPromoCode:"Enter Promo Code"
    },
    ProvidersGames:{
        ProvidersGames:"Providers Games"
    },
    rules:{
        EmailVerification:"Please enter the correct email address",
        MobileVerification:"Please enter the correct mobile phone number",
        MobileVerificationShort:"The mobile phone number must be more than 8digits",
        MobileVerificationLong:"Exceeding maximum length",
        CodeRequire:"Verification Code is Require",
        PasswordRules:"The two passwords are inconsistent",
        CheckAgreement:"Please check the agreement",
        PasswordFormat:"Passwords must consist of numbers and letters, and must be between 6 and 12 characters in length",
    },
    TopTips:{
        sendCodeSuccess:"send code success",
        sendCodeFailed:"send code failed",
        registerSuccess:"register success",
        findPasswordSuccess:"find password success",
        ActivityNotOpened:"Activity not opened",
        BindEmailSuccess:"bind email success",
        BindMobileSuccess:"bind mobile success",
        changeAvatarSuccess:"change avatar success",
        changeAvatarFailed:"change avatar failed",
        changeNameSuccess:"change name success",
        changeNameFailed:"change name failed",
        ReceiveSuccess:"receive success",
        ReceiveFailed:"receive failed",
        NotArrived:"The collection time has not arrived yet",
        ModifyPasswordSuccess:"Modify password success",
        CopySuccess:"copy success",
        CopyFailed:"copy Failed",
        reachedTime:"You have reached the maximum number of spins today",
        ServerInternalError:"Server Internal Error",
        CompleteAccount:"Complete account and privacy before withdrawal",
        WithdrawalSuccess:"Withdrawal Success",
        RouletteButton1:"Silver",
        RouletteButton2:"Gold",
        RouletteButton3:"Diamond",
        "Pleasewait": "Please wait",
        Start:"Start"
    },
    WithdrawalPIX:{
        WithdrawalPIX:"Withdrawal PIX",
        WithdrawalUSDT:"Withdrawal USDT",
        WithdrawalAmount:"Withdrawal Amount",
        AvailableBalance:" Available Balance",
        RolloverProgress:" Rollover Progress",
        PIXsCPFAccount:"PIX's CPF Account",
        PIXAccount:"PIX Account",
        FirstName:"First Name",
        LastName:"Last Name",
        AmountBRL:"Amount(BRL)",
        Money:"money",
        MoneyisRequired:"Money is Required",
        Pleaseenteramount:"Please enter amount",
        WithdrawalLimit:"Withdrawal Limit",
        HandlingFee:"Handling fee",
        Withdraw:"Withdraw",
        Available:"Available",
        WithdrawalSuccess:"Withdrawal Success",
        content_1:"If withdrawal is not received within 10 minutes,please contact Live Support for further assistance",
        content_2:"Enter the PIX information",
        content_3:"Before withdrawal, make sure you have registered your CPF as a PIX key in your banking application",
        content_4:"1. Open the banking app",
        content_5:"2. Go to the PIX section of the banking app",
        content_6:"3. Select My Keys",
        content_7:"4. Select the option 'register key' and register your CPF",
        content_8:"5. As soon as your bank confirms that the key is registered, you will be able to receive withdrawals via PIX",
        content_9:"Input Money must be an integer",
        content_10:"Money is not in the range.",  
        content_11:"Number of withdrawals made today:",
        content_12:"Amount withdrawn today:" ,
        conteent_13:"Daily withdrawal times and amount limits increase with vip level",
        content_14:"Please fill in your CPF completely",
    },
    ComingSoon:{
        ComingSoon:"Coming Soon",
        ActivityIsComingSoon:"Activity is coming soon",
    },
    LotteryDraw:{
        LotteryDraw:"Lottery Draw",
        NoviceLottery:"Novice Lottery",
        GetBonus:"Get your bonus",
        GoDraw:"Go to the draw",
        times:"times",
        record:"Victory record",
        BonusUnlockMissions:"Bonus Unlock Missions",
        DailyLottery:"Daily Lottery",
        ActivityEnd:"The activity has ended",
    },
    LotteryDrawListView:{
        RewardList:"Reward List",
        ActivityName:"Activity Name",
        Amount:"Amount of Premiums",
        Time:"Time"
    },
    LotteryHelp:{
        LotteryHelp:"Lottery Help",
        LotteryRules:"Lottery Rules",
    },
    Slots:{
        StartGame:"Start game",
        BonusTotal:"Bonus total",
        Countdown:"Remaining time in the lottery",
    },
    SlotsList:{
        Awards:"Awards",
        Username:"Username",
        ActivityName:"Activity name",
        SlotsList:"Reward list",
        noDateText:"No date",
    },
    SlotShare:{
        SlotShare:"Slot Share",
        View:"View Bonus"
    },
    GamePop: {
        Gamesaved: "Game saved",
        Gamesavereplaced: "Game save replaced",
        Gamesavecanceled: "Game save canceled",
        NONE:"None",
    },
    redPacket:{
        redPacketTitle:"Loss allowance",
        rulesText:"Explanation",
        YesterdayLosses:"Yesterday losses",
        shootTime:"The number of penalty shot",
        content_1:"1.Lost yesterday's game and you can get a bonus today by shooting football,The more your losses, the more chances you'll get to shoot",
        content_2:"2.You will receive a random bonus after each penalty shot",
        content_3:"3.Settlement time began at 0:00:00AM~1:00:00AM every day and no available shooting during that period.",
        content_4:"4.when you have available shooting chances you can keep and use it. But when you lose games on a new day your previous shooting chances and goal areas will also be reset.",
        content_5:"5.platform has the power to maintain the final interpretation of this activity",
        ShootingChancesisout:"Shooting chances is out",
        ThisAreaHasBeenHit:"This area has been hit",
        historyText:"history",
        chanceLeft:"chance left",
        Tips:"click the flag",
    },
    redPacketList:{
        redPacketListTiltle:"record",
        TotalShots:"Total of shots",
        TotalBonus:"Total of bonus",
        ShotTime:"Shot time",
        Bonus:"Bonus",
    },
    weeklyList:{
        Title:"Weekly bet rank",
        Countdown:"Countdown",
        Bet:"Bet",
        LastWeekBonus:"Last week bonus",
        MyRanking:"My ranking",
        Receive:"Receive",
        Received:"Received",
        PromotionRules:"Promotion rules",
        Ranking:"Ranking",
        RewardAmount:"Reward amount",
        content_1:'1.Every week users’ betting counted for ranking',
        content_2:'2.At the end of each period’s ranking, the top 10 users will receive bonus.',
        content_3:'3.The Xfun reserves the right to verify the account holder or prohibit the participation of any participant in this event at any time.'
    },
    weeklyCard:{
        Title:"Weekly Bonus",
        Now:"Now",
        ExtraBonus:"Extra bonus",
        Bonus:"Bonus",
        days:"days",
        day:"day",
        Extra:"Extra",
        Receive:"Receive",
        AllBonus:"All bonus",
        NextTime:"Next time",
        ExpireDate:"Expire date",
        AllClaimed:"All claimed",
        soldOut:"sold out",
        Available:"Available",
        Claim:"Claim",
        Locked:"Locking",
        Sharetitle:"Congratulations",
        disable_text1:'VIP',
        disable_text2:"level available for purchase",
        NextAvailableTime:"Next available time",
        PromotionInstructions:"Weekly bonus description",
        content_1:"1.Only despoit available amount from card page then the bonus can be activated",
        content_2:"2.Despoit will be credited immediately",
        content_3:"3.You can receive whole bonus within 7 days. For the specific bonus please refer to the description information of card (if you forget to receive bonus,then it will be automatically issued at 0:00 the next day)",
        content_4:"4.Each weekly card can only be purchased once in week and can be purchased again after expiration.",
        content_5:"5.Three levels of weekly cards can be purchased at the same time and the bouns is independent.",
    },
    LimitedTimeRecharge:{
        title:"Limited time recharge rebate",
        content_1:"Congratulations on qualifying for the event",
        content_2:"During the event, every eligible recharge will receive additional rewards",
        Enter:"Join now",
    },
    RedemptionCode:{
        title:"Redemption Code", 
        placeholder:"Enter redeem promotional code",
        label:"Redemption Code",
        apply:"Apply",
        RedemptionSuccess:"Redemption successful",
        RedemptionFailed:"Redemption failed",
        RedemptionSteps:"Redemption Steps",
        Redemption:"Aplicar",
        content_1:"1. You can get the redemption code through Instagram, Facebook, Twitter, etc.",
        content_2:"2. Login to Xfun",
        content_3:"3. Enter the redemption code and click 【Apply】",
        content_4:"4. Get rewards and enjoy the game",
        content_5:"Please continue to follow our forums and social media to get the latest redemption codes, and good luck to everyone" 
    },
    downLoad:{
        content1:"After the download ,apply bouns from customer service, receive up to R$50",
        content2:"Download the game and start now",
        download:"Download",
    },
    bonusRecord:{
        title:"Bonus Record",
        Activity:"Activity",
        type:"Type",
        value:"Value",
        bonuses:"Bonuses",
        credits:"Credits",
        VIPPoints:"VIP points",
        time:"Time",
        bonus:"bonuses",
        BalanceAmount:"Balance",
        pointsText:"points"
    },
    SharingRebates:{
        title:'Sharing Rebates',
        content_1: 'Members who deposited on same day can get bonus by posting on social media!e',
        content_2: 'The bonus only be claimed once per day', 
        TodayDeposit:"Today deposit",
        Post:"Post",
        Claim:"Claim",
        deposit:"Deposit",
        done:"Done",
    },
    activeCenter:{
        checkin:"Join",
    },
    calendarComp:{
        title:"Select Date",
        begin:"Begin",
        end:"End",
        confirm:"Confirm",
    },
    downloadPWA:{
        content:"After installation, register and contact customer service to receive a random bonus of $10.",
        Cancel:"Cancel",
        Continue:"Continue",
    },
    redEnvelopeTime:{
        title:"Cash Rain",
        content1:"1.If the daily cumulative recharge meets the standard, you can participate in all activities throughout the day",
        content2:"2.You need to be online and manually claim the bonus during the event period. If you miss the time, you cannot make up for it",
        content3:"3.Maximum bonus per round",
        content4:"4.The bonus will be directly transferred to your wallet",
        deposit:"Deposit",
        next:"Next",
        claim:"Claim",
        YourBonus:"Your Bonus",
        MaximumBonus:"Maximum Bonus"
    },
    FbGuide:{
        title1:"Method One",
        click:"Click",
        choose:"Choose",
        openMenu:"Open Menubar",
        openBrowser:"Open in Browser",
        title2:"Method Two",
        copy:"Copy",
        cloneOpen:"Browse Clone to open"
    },
    RedRain:{
        MAX:"MAX",
        Comecaas:"Start the"
    },
    ...en,
}