import en from '@/locales/en'
import pt from '@/locales/pt'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'pt',
  messages: {
    en: en,
    pt: pt
  }
}))