import { pt } from 'vuetify/locale';

export default {
    select: {
        title: "língua",
    },
    tabbar: {
        item1: "Casa",
        item2: "Depositar",
        item3: "Convidar",
        item4: "Bónus",
        item5: "Pessoal",
        tipsContent: "O utilizador actual iniciou sessão noutro dispositivo?",
        Tips:"Dicas"
    },
    appbar: {
        deposit: "Depositar",
        register:"Registar",
        login:"Entrar",
        download:"Transferir",
        Earn:"Ganhar",
        Bonus:"Bônus",
    },
     // 页尾
    pagefooting: {
        AboutUS: "Sobre nós",
        content: "Este site oferece uma experiência arriscada de jogos. Para ser um usuário do nosso site, você deve ter mais de 18 anos de idade. nós não somos responsáveis",
        copyright: "©2023 XFUN.APP ALL RIGHTS RESERVED.",
        RightsReserved:"Todos os direitos reservados."
    },
    // 搜索栏
    search: {
        Search: "Procurar",
        Recommended: "Jogos Recomendados",
        placeholder:"Procurar Jogos",
        noMore:"Não Há Mais Itens",
        loading:"Carregando"
    },
    logindialog: {
        "Email": "Email",
        "Enter email address": "Enter email address",
        "Mobile": "Mobile",
        "PassWord":"PassWord",
        "Forgotloginpassword?":"Forgot login password?",
        "Login":"Login",
        "SignUpnnow":"Sign up now",
        "Rememberme": "Lembrar de mim"
    },
     //个人信息
     userinfo: {
        Profile: "Meu Perfil",
        EditProfile:" Editar Perfil",
        Balance: "Saldo",
        BalanceTrend:"Saldo Total",
        Deposit:"Depositar",
        Withdraw:"Retirar",
        Transaction:"Transações",
        BetsHistory:"Histórico",
        RolloverProgress:"Progresso do rollover",
        StreamingCentre:"Função",
        AccountPrivacy:"Conta & Privacidade",
        Password:"Senha",
        Messages:"Mensagens",
        promotion:"Promoções",
        Logout:"Sair",
        ExitAccount : "Você deseja sair do usuário atual?",
        CompleteAccount:"Conta completa e privacidade antes da retirada",
        Other:"Outro",
        invite:"Convidar",
        readRain:"LerRain",
        Welcome:"Bem-vindo ao WWW.XFUN.APP",
        LoginOrRegister:"Por favor, cadastre-se ou faça login primeiro.",
        LiveChat:"Bate-papo ao vivo",
    },
    //修改头像
    changeavatar: {
        ChangeAvatar:"Alterar Avatar", 
        Avatatr:"Avatatr", 
        Animal:"Animal",
        confirm:"Confirmar",
    },
    //修改昵称
    editProfile:{
        EditProfile:"Editar Perfil",
        NickName:"Apelido",
        DisplayInformation:"Informação de exibição",
        PersonalInformation:"Informação pessoal",
        MobilePhone:"Telefone celular",
        Email:"E-mail",
        name:"Nome",
        phone:"Telefone",
        confirm:"Confirmar",
    },
    //充值
    deposit:{
        Deposit:"Depositar",
        Recommended:"Recomendado",
        OtherOption:"Outra opção",
        OptionofDeposit:"Opção de Depositar",
        Earn:"Ganhar",
        Bonus:"Bônus",
    },
    bindCPF:{
        BindCPF:"Vincular CPF",
        CPFAccount:"Conta CPF",
        FirstName:"Primeiro nome",
        LastName:"Último nome",
        EnterPIXsCPFAccount:"Digite a conta CPF do PIX",
        EnterFirstName:"Digite o primeiro nome",
        EnterLastName:"Digite o último nome",
        Remark:"Observação: Quando o CPF vinculado for bem-sucedido, apenas o CPF vinculado permitirá Depositar e retirada.",
        success:"sucesso",
        bindcpfsuccess:"Vincular CPF com sucesso",
        MoneyisRequired:"O dinheiro é necessário",
        Pixformaterror:"Erro de formato Pix",
        confirm:"Confirmar",
    },
    //提现
    withdrawal:{
        Withdrawal:"Retirar",
        OptionofWithdrawal:"Opção de Retirada",
        Recommended:"Recomendado",
        OtherOption:"Outra opção",
        According:"De acordo com nossa política de jogo justo, apenas membros verificados e ativados que fizeram um Depositar podem acessar nosso recurso de retirada. Pedimos gentilmente que você faça um Depositar de qualquer valor para desbloquear a função de retirada. Obrigado por sua cooperação e compreensão.",
    },
    //记录吧
    transaction:{
        Transaction:"Transações",
        Deposit:"Depositar",
        Withdraw:"Retirar",
        loadMore:"Carregar mais",
        Nomore:"Não há mais",
        NoRecords:"Sem registros",
    },
    //下注记录
    betsHistory:{
        BetsHistory:"Histórico de Apostas",
        TotalBet:"Aposta Total",
        TotalWin:"Total Ganho",
        BettingTime:"Tempo de Aposta",
        Bet:"Aposta",
        WinLose:"Ganhar/Perder",
        NoRecords:"Sem registros",
        selectDate:"Selecione a data",
        confirm:"Confirmar",
        cancel:"Cancelar",
    },
    //
    vip:{
        title:"VIP",
        Level:"Nível",
        Need:"Precisa",
        Achieve:"Alcançar",
        content1:"Permissões atuais de nível VIP",
        content2:"1: Suba de nível",
        content2_1:" ",
        content3:"2: Número máximo de saques diários:",
        content4:"3: Saque máximo diário:",
        content5:"4: Número de rodadas diárias: total",
        content6:"5: Bônus semanal que pode ser adquirido:Prata, Ouro, Diamante",
        content6_1:"5: Bônus semanal que pode ser adquirido: Prata, Ouro, Diamante, SVIP",
        content7:"6: Desbloqueie novos avatares de personagens",
        rules1:"Como obter experiência VIP",
        rules2:"1. Você pode ganhar experiência VIP por meio de uma rotatividade de apostas eficaz.",
        rules3:"2. Aposta efetiva: R$1=1 experiência",
       
    },
    depositPIX:{
        DepositPIX:"Depositar PIX",
        give:"+R$",
        ExtraGive:"Extra+R$",
        AvailableBalance:"Saldo disponível",
        money:"Dinheiro",
        Pleaseenteramount:"Por favor, insira o valor",
        Deposit:"Depositar",
        FirstDeposit:"Primeiro Depositar",
        content:"Se o Depositar não for recebido em 10 minutos, entre em contato com o Suporte ao Vivo para obter mais assistência.",
        Back:"Voltar",
        MoneyRules:"As entradas de dinheiro devem ser números inteiros",
        MoneyRules_1:"O dinheiro não está ao alcance.",
        MoneyRules_2:"É necessário dinheiro.",
        limitTimeText:"Participe de atividades de recarga e descontos por tempo limitado",
        firstDepositText:"Faça seu primeiro Depositar e ganhe um bônus de até"
    },
    DepositUSDT:{
        DepositUSDT:"Depositar USDT",
        give:"+R$",
        ExtraGive:"Extra+R$",
        AvailableBalance:"Saldo disponível",
        money:"Dinheiro",
        Pleaseenteramount:"Por favor, insira o valor",
        Deposit:"Depositar",
        content:"Se o Depositar não for recebido em 10 minutos, entre em contato com o Suporte ao Vivo para obter mais assistência.",
        Back:"Voltar",
        MoneyRules:"As entradas de dinheiro devem ser números inteiros",
        MoneyRules_1:"O dinheiro não está ao alcance.",
        MoneyRules_2:"É necessário dinheiro.",
    },
    bindMobile:{
        BindMobile:"Vincular celular",
        Mobile:"Celular",
        SendCode:"Enviar Código",
        EnterMobileNo:"Digite o número do celular.",
        VerificationCode:"Código de verificação",
        Code:"Código",
        EnterVerificationcode:"Digite o código de verificação",
        confirm:"Confirmar",
    },
    bindEmail:{
        BindEmail:"Vincular E-mail",
        EmailAddress:"Endereço de e-mail",
        EnterEmailAddress:"Digite o endereço de e-mail",
        VerificationCode:"Código de verificação",
        Code:"Código",
        EnterVerificationcode:"Digite o código de verificação",
        confirm:"Confirmar",
        SendCode:"Enviar Código",
        Email:"E-mail",
    },
    modifyPassword:{
        ModifyPassword:"Modificar Senha",
        CurrentPassword:"Senha Atual",
        EnterCurrentPassword:"Digite a Senha Atual",
        NewPassword:"Nova Senha",
        EnterNewPassword:"Digite a Nova Senha",
        ConfirmPassword:"Confirmar Senha",
        ReEnterNewpassword:"Digite a Nova Senha Novamente",
        confirm:"Confirmar",
    },
    Message:{
        Message:"Notificação",
        Messages:"Notificação",
        MessageDetail:"Detalhes da Mensagem",
        SystemMessages:"Sistema",
        personalMessage:"Pessoais",
        NoRecords:"Sem registros",
    },
    AccountAndPrivacy:{
        AccountAndPrivacy:"Conta e privacidade",
        LinkMobileNo:"Vincular número de celular",
        LinkEmailAddress:"Vincular endereço de e-mail",
    },
    promotion:{
        Share:"Referência",
        RegisterYesterday:"Registro ontem",
        TotalRegistration:"Registro Total",
        TotalBonus:"Bônus total",
        FirstDeposit:"Primeiro Depositar",
        BettingRewards:"Recompensas de Apostas",
        MyRewards:"Minhas recompensas",
        Total:"Total",
        TotalInvitedFriends:"Total de amigos convidados",
        ShareRecommendationLink:"Compartilhe este link ou código de convite com seus amigos",
        ShareCommunity:"Compartilhe com sua comunidade social",
        MyRecommendedCode:"Meu código de recomendação",
        RewardRules:"Regras de recompensa",
        FirstDeposits:"Primeiros Depositars",
        Reward:"Recompensa",
        All:"Tudo", 
        Level:"Nível",
        YesterDay:"Ontem",
        RuleSpecification:"Especificações da regra",
        content_1:"Ganhe cash back quando seus amigos apostarem",
        content_2:"O amigo convidado se cadastra e completa a primeira Depositar",
        content_3:"Convide um número específico de sub-usuários de receber recompensas.",
        content_6:" da aposta efetiva do seu amigo em ",
        content_6_1:" jogos será usado como comissão",
        content_7:"Os amigos que você convida diretamente são de nível 1, os amigos convidados de nível 1 são de nível 2 e os amigos convidados de nível 2 são de nível 3.",
        contenet_7_1:"A taxa de comissão que você pode ganhar é",
        content_8:"O bônus será distribuído automaticamente e você poderá apostar ou sacar.",
        content_9_1:"Usuários válidos são novos usuários que primeiro depositam mais de",
        content_9_2:"e se cadastram usando o link de convite",
        content_10:"É proibido usar o mesmo IP e o mesmo dispositivo para criar contas por comportamento de arbitragem.",
        content_11:"Qualquer comportamento ilegal é estritamente proibido, Reservamo-nos o direito de cancelar qualquer bônus obtido por meio de fraude (seja um único jogador ou um grupo de jogadores), incluindo a dedução do bônus da conta e o direito de participar e tomar as medidas necessárias para recuperar o valor perdido devido ao comportamento de arbitragem.",
        chatGroup:"Grupo oficial de CHAT do agente XFUN Telegram",
        chatGroupText:"Clique no botão do ícone à esquerda para ingressar no nosso grupo de aliança oficial TG. Forneceremos a você linguagem de aliança profissional e ferramentas de aliança profissional. Ajude você a obter benefícios reais.",
        chatGroupText1:"Se você tem um grande público e fãs. Oferecemos condições especiais para personalizar seu programa de indicações! Para mais detalhes, entre em contato conosco: "
    },
    promotionAbout:{
        question1:'O que é o XFUN convidando amigos?',
        answer1:'XFUN convidar amigos é um projeto onde lhe proporcionamos a oportunidade de ganhar dinheiro todos os dias. Você só precisa nos dar a oportunidade de receber mais jogadores.',
        question2:'Quanto custa para participar do evento?',
        answer2:'Totalmente grátis!',
        question3:'Posso obter uma bolsa mesmo que não tenha um site?',
        answer3:'claro. Você pode usar a mídia social ou e-mail para marketing.',
        question4:'Qual é a minha renda?',
        answer4:'Como membro da nossa rede de afiliados, você será remunerado com base em um modelo de participação nos lucros. Você pode obter mais de 70% de recompensa de comissão.',
        answer5:'Cada vez que um jogador indicado por você fizer uma aposta, você receberá uma porcentagem variável da comissão, independentemente de ele ganhar ou perder.',
    },
    DailyTasks:{
        DailyTasks:"Tarefas diárias",
        points:"Complete as tarefas para ganhar pontos",
        Receive:"Receber",
        Done:"Feito",
        Open:"Aberto",
        NoRecords:"Sem registros",
        bonus:"Bônus",
        GiftAmount:"créditos",
        pointsText:"pontos",
        new1:"Novo",
        Daily:"Diariamente",
        Weekly:"Semanalmente",
        content_help_1:"1. Tarefas para novos usuários: apenas uma vez",
        content_help_2:'2. Tarefas diárias: atualize às 0:00 todos os dias',
        content_help_3:"3. Tarefas semanais: atualize todas as segundas-feiras às 0h",
        content_help_4:'4. Receba bônus, créditos e pontos após completar tarefas.Se seus pontos atingirem a meta do baú do tesouro, você poderá abrir e receber bônus.',
        content_help_5:"5. Os pontos e o baú do tesouro são atualizados às 0h todas as segundas-feiras",
        title:"Novo Membro",
        Ends:"Acaba:"
    },
    DailyTasksHistory:{
        title:"Histórico de tarefas diárias",
        bonus:"Bônus",
        GiftAmount:"créditos",
        Total:"Total",
        Time:"Tempo",
        NoRecords:"Sem registros",
        Reward:"Recompensa",
        selectDate:"Selecione a data",
        piont:"pontos",
    },
    gameSection:{
        More:"Mais",
    },
    RouletteDetail:{
        RouletteDetail:"Detalhe da roleta",
        MYscoresDetails:"Detalhes dos meus pontos",
        CurrentPoints:"Pontos atuais",
        Points:"Pontos",
        Period:"Período",
        SignIn:"Assinar",
        Deposit:"Depositar",
        Betting:"Apostas",
        BettingGuests:"Apostas de convidados",
        DepositedGuests:"Convidados depositados",
        MyHistory:"Minha história",
        Meeting:"Reunião",
        Model:"Modelo",
        consume:"Consumir",
        obtain:"Obter",
        confirm:"Confirmar",
        cancel:"cancelar",
        Scores:"Pontos",
        Rewards:"Recompensas",
        Nomore:"Não há mais",
    },
    RouletteRules:{
        Rules:"FAQ",
        Title:"Regras da Roleta"
    },
    RouletteLog:{
        RouletteRules:"Recorde de Bônus",
        Record:"Registro",
        level:"Nível",
        Prize:"Prêmio",
        Vip:"Vip"
    },
    RouletteShare:{
        RouletteShare:"Compartilhar roleta",
        Congratulations:"Parabéns",
        ViewPoints:"Ver Bônus",
    },
    Roulette:{
        Score:"Pontuação",
        InsufficientPoints:"Não tem chance suficientes",
    },
    login:{
        EnterEmailAddress:"Digite o endereço de e-mail",
        Mobile:"Celular",
        EnterMobileNo:"Digite o número do celular",
        Password:"Senha",
        NewPassword:"Nova Senha",
        ForgotLoginPassword:"Esqueceu a senha de login",
        RememberMe:"Lembrar de mim",
        SignUpNow:"Registre-se agora",
        Login:"Entrar",
        EnterAccountNumber:"Número de telefone / E-mail",
    },
    fab: {
        Title:"Bônus",
        Function:"Funcionalidade",
        Activity:"Função"
    },
    firstCharge:{
        DepositRewards:"Recompensas de Depositar",
        Deposit:"Depositar",
        PromotionStandard : "Padrão de promoção",
        content1:"Quando o membro recém-cadastrado depositar",
        content2:"pela primeira vez, ele receberá um bônus de até",
        content3:"O bônus será automaticamente transferido para sua carteira.",
    },
    forgot:{
        EnterEmailAddress:"Digite o endereço de e-mail",
        Mobile:"Celular",
        EnterMobileNo:"Digite o número do celular",
        MobileNo:"Telefone",
        Code:"Código",
        EnterVerificationCode:"Digite o código de verificação",
        SendCode:"Enviar Código",
        EnterYourPassword:" Insira sua senha",
        ReEnterPassword:"Digite a senha novamente",
        LoginInNow:"Faça login agora",
        Password:"Senha",
        Continue:"Continuar",
        Email: "E-mail",
        VerificationCode:"Código de verificação",
    },
    register:{  
        VerificationCode:"Código de verificação",
        SendCode:"Enviar Código",
        Password:"Senha",
        content_1:"Eu concordo com o acordo do usuário",
        RememberMe:"Lembrar de mim",
        Continue:"Continuar",
        LoginInNow:"Faça login agora",
        Email: "E-mail",
        EnterEmailAddress: "Indique o endereço de e-mail",
        Mobile: "Celular",
        MobileNo:"Telefone",
        EnterMobileNo:"Digite o número do celular",
        Code:"Código",
        EnterVerificationCode:"Digite o código de verificação",
        EnterYourPassword:" Insira sua senha",
        EnterPromoCode:"Digite o código promocional",
    },
    ProvidersGames:{
        ProvidersGames:"Jogos dos Fornecedores"
    },
    rules:{
        EmailVerification:"Introduza o endereço de e-mail correcto",
        MobileVerification:"Introduza o número de telefone correcto",
        MobileVerificationShort:"O número de telefone celular deve ter mais de 8 dígitos",
        MobileVerificationLong:"Superior ao comprimento máximo",
        CodeRequire:"É necessário o código de verificação",
        PasswordRules:"As duas senhas são inconsistentes",
        CheckAgreement:"Por favor, verifique o acordo",
        PasswordFormat:"As senhas devem consistir em números e letras e ter entre 6 e 12 caracteres de comprimento",
    },
    TopTips:{
        sendCodeSuccess:"Enviar Código com sucesso",
        sendCodeFailed:"Falha ao enviar código",
        registerSuccess:"Registro bem-sucedido",
        findPasswordSuccess:"Encontrar senha com sucesso",
        ActivityNotOpened:"A atividade ainda não foi aberta",
        BindEmailSuccess:"Vincular e-mail com sucesso",
        BindMobileSuccess:"Vincular celular com sucesso",
        changeAvatarSuccess:"Alterar avatar com sucesso",
        changeAvatarFailed:"Falha ao alterar o avatar",
        changeNameSuccess:"Alterar nome com sucesso",
        changeNameFailed:"Falha ao alterar o nome",
        ReceiveSuccess:"Receber com sucesso",
        ReceiveFailed:"Falha ao receber",
        NotArrived:"A hora de recolha ainda não chegou",
        ModifyPasswordSuccess:"Alterar senha com sucesso",
        CopySuccess:"Copiar com sucesso",
        CopyFailed:"Falha ao copiar",
        reachedTime:"Você atingiu o número máximo de rodadas hoje",
        ServerInternalError:"Erro interno do servidor",
        CompleteAccount:"Conta completa e privacidade antes da retirada",
        WithdrawalSuccess:"Retirada bem-sucedida",
        RouletteButton1:"Prata",
        RouletteButton2:"Ouro",
        RouletteButton3:"Diamante",
        Pleasewait: "Por favor, espere um momento.",
        Start:"Começar"
    },
    
    WithdrawalPIX:{
        WithdrawalPIX:"Retirar PIX",
        WithdrawalUSDT:"Retirar USDT",
        WithdrawalAmount:"Valor da retirada",
        AvailableBalance:"Saldo disponível",
        RolloverProgress:"Progresso da rolagem",
        PIXsCPFAccount:"Conta CPF do PIX",
        PIXAccount:"Conta PIX",
        FirstName:"Primeiro nome",
        LastName:"Último nome",
        AmountBRL:"Valor (R$)",
        Money:"Dinheiro",
        MoneyisRequired:"O dinheiro é necessário",
        Pleaseenteramount:"Por favor, insira o valor",
        WithdrawalLimit:"Limite de retirada",
        HandlingFee:"Taxa de manuseio",
        Withdraw:"Retirar",
        Available:"Disponível",
        WithdrawalSuccess:"Retirada bem-sucedida",
        content_1:"Se a retirada não for recebida dentro de 10 minutos, entre em contato com o Suporte ao vivo para obter mais assistência",
        content_2:"Insira as informações do PIX",
        content_3:"Antes do saque, certifique-se de ter cadastrado seu CPF como chave PIX no seu aplicativo bancário",
        content_4:"1. Abra o aplicativo bancário",
        content_5:"2. Acesse a seção PIX do app do banco",
        content_6:"3. Selecione Minhas Chaves",
        content_7:"4. Selecione a opção ‘cadastrar chave’ e cadastre seu CPF",
        content_8:"5. Assim que seu banco confirmar que a chave está cadastrada, você poderá receber saques via PIX",
        content_9:"A entrada de dinheiro deve ser um número inteiro",
        content_10:"O dinheiro não está ao alcance.",
        content_11:"Número de desenhos feitos hoje:",
        content_12:"Montante sacado hoje:",
        content_13:"Os tempos de retirada diários e limites de quantidade aumentam com o nível VIP",
        content_14:"Por favor preencha o CPF completamente",
    },
    ComingSoon:{
        ComingSoon:"Em breve",
        ActivityIsComingSoon:"A atividade está chegando",
    },
    LotteryDraw:{
        LotteryDraw:"Sorteio da Lotaria",
        NoviceLottery:"Sorteio para iniciantes",
        GetBonus:"Receba seu bônus",
        GoDraw:"Ir ao sorteio",
        times:"vezes",
        record:"Registro de vitórias",
        BonusUnlockMissions:"Bônus desbloquear missões",
        DailyLottery:"Sorteio diário",
        ActivityEnd:"A actividade terminou"
    },
    LotteryDrawListView:{
        RewardList:"Lista de Prémios",
        ActivityName:"Nome da Atividade",
        Amount:"Montante dos Prêmios",
        Time:"Tempo",
    },
    LotteryHelp:{
        LotteryHelp:"Ajuda da Lotaria",
        LotteryRules:"Regras da Lotaria",
    },
    Slots:{
        StartGame:"Iniciar o jogo ",
        BonusTotal:"Total de bônus",
        Countdown:"O tempo restante na loteria",
    },
    SlotsList:{
        Awards:"Prêmios",
        Username:"Nome de usuário",
        ActivityName:"Nome da atividade",
        SlotsList:"Lista de prémios",
        noDateText:"Sem dados",
    },
    SlotShare:{
        SlotShare:"Compartilhar Slot",
        View:"Ver Bônus",
    },
    GamePop: {
        Gamesaved: "Jogo Salvo",
        Gamesavereplaced: "Salvamento do jogo substituído",
        Gamesavecanceled: "Salvamento do jogo cancelado",
        NONE:"Nenhum"
    },
    redPacket:{
        redPacketTitle:"Subsídio de perdas",
        rulesText:"Explicação",
        YesterdayLosses:"Perdas de ontem",
        shootTime:"O número de pênaltis",
        content_1:"1.Perdeu o jogo de ontem e você pode ganhar o bônus hoje jogando futebol,Quanto mais perdas você tiver, mais chances você terá de atirar",
        content_2:"2.Você receberá um bônus aleatório após cada pênalti",
        content_3:"3.O horário de liquidação começou entre 0:00 AM e 1:00 AM todos os dias e não há filmagem disponível durante esse período.",
        content_4:"4.quando você tiver chances de tiro disponíveis, poderá mantê-las e usá-las. Mas quando você perde jogos em um novo dia, suas chances de arremesso e áreas de gol anteriores também serão redefinidas.",
        content_5:"5.plataforma tem o poder de manter a interpretação final desta atividade",
        ShootingChancesisout:"As chances de tiro acabaram",
        ThisAreaHasBeenHit:"Esta área foi atingida",
        historyText:"história",
        chanceLeft:"chance restante",
        Tips:"clique na bandeira"
    },
    redPacketList:{
        redPacketListTiltle:"registro",
        TotalShots:"Total de tiros",
        TotalBonus:"Total de bônus",
        ShotTime:"Tempo de tiro",
        Bonus:"Bônus",
    },
    weeklyList:{
        Title:"Classificação de apostas semanal",
        Countdown:"Contagem regressiva",
        Bet:"Aposta",
        LastWeekBonus:"Bônus da semana passada",
        MyRanking:"Minha classificação",
        Receive:"Receber",
        Received:"Recebido",
        Ranking:"Classificação",
        RewardAmount:"Valor da recompensa",
        PromotionRules:"Regras de promoção",
        content_1:'1.Todas as semanas as apostas dos usuários contam para a classificação',
        content_2:'2.Ao final da classificação de cada período, os 10 melhores usuários receberão bônus.',
        content_3:'3.A Xfun reserva-se o direito de verificar o titular da conta ou proibir a participação de qualquer participante neste evento a qualquer momento.'
    },
    weeklyCard:{
        Title:"Bônus Semanal",
        Now:"Agora",
        ExtraBonus:"Bônus extra",
        Bonus:"Bônus",
        days:"dias",
        day:"dia",
        Extra:"Extra",
        Receive:"Receber",
        AllBonus:"Todos os bônus",
        NextTime:"Próximo tempo",
        ExpireDate:"Data de validade",
        NextAvailableTime:"Próximo horário disponível",
        AllClaimed:"Tenha recebido tudo",
        soldOut:"vendido ",
        Claim:"Pegar",
        Sharetitle:"Parabéns",
        Available:"Disponível",
        Locked:"Bloqueio",
        disable_text1:'Nível VIP',
        disable_text2:"disponível para compra",
        PromotionInstructions:"Descrição do bônus semanal",
        content_1:"1.Despoje apenas o valor disponível na página do cartão e o bônus poderá ser ativado",
        content_2:"2.O despojo será creditado imediatamente",
        content_3:"3.Você pode receber o bônus completo em 7 dias. Para o bônus específico, consulte as informações de descrição do cartão (se você esquecer de receber o bônus, ele será emitido automaticamente às 0h do dia seguinte)",
        content_4:"4.Cada cartão semanal só pode ser adquirido uma vez por semana e pode ser adquirido novamente após o vencimento.",
        content_5:"5.Três níveis de cartas semanais podem ser adquiridos ao mesmo tempo e os bouns são independentes.",
    },
    LimitedTimeRecharge:{
        title:"Desconto de recarga por tempo limitado",
        content_1: "Parabéns pela qualificação para o evento",
        content_2:"Durante o evento, cada recarga elegível receberá recompensas adicionais",
        Enter:"Entrar",
    },
    RedemptionCode:{
        title:"Código de reembolso",
        placeholder:"Insira o código promocional de resgate",
        label:"Código de reembolso",
        apply:"Aplicar",
        RedemptionSteps:"Etapas de resgate",
        RedemptionSuccess:"Resgate bem-sucedido",
        RedemptionFailed:"Falha no resgate",
        Redemption:"Aplicar",
        content_1:"1. Você pode obter o código de resgate através do Instagram, Facebook, Twitter, etc.",
        content_2:"2. Faça login no xfun",
        content_3:"3. Insira o código de resgate e clique em 【Aplicar】",
        content_4:"4. Receba recompensas e aproveite o jogo",
        content_5:"Continue acompanhando nossos fóruns e mídias sociais para obter os códigos de resgate mais recentes e boa sorte a todos",
    },
    downLoad:{
        content1:"Após o download, aplique bônus do atendimento e receba até R$ 50",
        content2:"Baixe o jogo e comece agora",
        download:"Baixar",
    },
    bonusRecord:{
        title:"Recorde de bônus",
        Activity:"Atividade",
        type:"Tipo",
        value:"Valor",
        bonuses:"Bônus",
        credits:"Créditos",
        VIPPoints:"Pontos VIP",
        time:"Tempo",
        bonus:"Bônus",
        BalanceAmount:"Dinheiro",
        pointsText:"pontos",
    },
    SharingRebates:{
        TodayDeposit:"Today deposit",
        title:'Sharing Rebates',
        content_1: 'Os membros que depositaram no mesmo dia podem ganhar bônus postando nas redes sociais!',
        content_2: 'O bônus só pode ser reivindicado uma vez por dia', 
        Post:"Publicar",
        Claim:"Alegar",
        deposit:"Depositar",
        done:"Feito"
    },
    activeCenter:{
        checkin:"Juntar",
    },
    calendarComp:{
        title:"Seleccionar a Data",
        confirm:"Confirmar",
        begin:"Início",
        end:"Fim",
    },
    downloadPWA:{
        content:"Após a instalação, você pode receber bônus aleatórios",
        Cancel:"Cancelar",
        Continue:"Continuar",
    },
    redEnvelopeTime:{
        title:"Chuva de Dinheiro",
        content1:"1.Se a recarga cumulativa diária for de acordo com o padrão, você pode participar em todas as atividades ao longo do dia",
        content2:"2.Você precisa estar online e resgatar manualmente o bônus durante o período do evento. Se você não estiver presente durante esse período, você não será recompensado",
        content3:"3.Bônus máximo por rodada",
        content4:"4.O bônus será transferido diretamente para sua carteira",
        deposit:"Depositar",
        next:"Próximo",
        claim:"Afirmação",
        YourBonus:"Seu bônus",
        MaximumBonus:"Bônus Máximo",
    },
    FbGuide:{
        title1:"Método um",
        click:"Clicado",
        choose:"Escolher",
        openMenu:"Abra a barra de menus",
        openBrowser:"Aberto no navegador",
        title2:"Método dois",
        copy:"Copiar",
        copyLink:"Copiar link",
        cloneOpen:"Cole no navegador para abrin",
    },
    RedRain:{
        MAX:"MAX",
        Comecaas:"Comeca as"
    },
    ...pt,
}